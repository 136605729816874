import { IconName } from '@waypoint/ui-framework/dist/typescript/icon-map';

const iconConverter = {
  'fa-info-circle': 'alert-info'
};

export const iconMapper = (className: string): IconName | undefined => {
  const isADPUnified = !window['isLegacyAppShell'];

  // Don't convert icons that are in the legacy app shell because they won't appear.
  if (!isADPUnified) {
    return undefined;
  }

  const iconClasses = className?.split(' ')
    .filter((name) => (name === 'fa' || name.includes('fa-') || name.includes('icon-')));

  const mappedIcon = iconClasses?.map((icon: string) => iconConverter[icon]).filter((icon: string) => !!icon);

  if (mappedIcon?.length > 1) {
    console.error(`iconMapper(): className "${className}" contains more than 1 icon`);
  }

  return mappedIcon?.[0];
};
